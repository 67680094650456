@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter' , 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.default-font {
  font-family: -apple-system, BlinkMacSystemFont, 'Inter' , 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.title-font {
  font-family: -apple-system, BlinkMacSystemFont, 'IBM Plex Sans' , 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.25em solid rgba(255, 255, 255, 0.2);
  border-right: 0.25em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.25em solid rgba(255, 255, 255, 0.2);
  border-left: 0.25em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.gradient-shadow {
  background: linear-gradient(-45deg, #ffa63d, #338aff, #3cf0c5, #84cc16);
  background-size: 600%;
  animation: gradient-animation 4s linear infinite;
  filter: blur(50px);
  opacity: 0.8;
}

.animate-scale {
  animation: scale-animation 16s linear infinite;
}

@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-o-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes scale-animation {
  0% {
    transform: scale(1.1);
    opacity: 1.0;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.1);
    opacity: 1.0;
  }
}

.hero-line {
  animation: hero-line-animation 2s linear infinite;
}

@keyframes hero-line-animation {
  0% {
    stroke-dashoffset: 100;
    stroke-dasharray: 50;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 50;
  }
}

@keyframes typing-animation {
  from { width: 0 }
  to { width: 100% }
}

@keyframes fade-in-out-animation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}